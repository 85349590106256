export default function useBaseValidation() {
  function required(v) {
    return (v != null && !!v.toString()) || 'Обязательное поле';
  }

  function requiredImage(image: string) {
    return (v) => image.length > 0 || required(v);
  }

  function requiredArray(v: []) {
    return (!!v && !!v.length) || 'Обязательное поле';
  }

  function requiredObject(v) {
    return (!!v && !!v.id) || 'Обязательное поле';
  }

  function email(v) {
    return /.+@.+\..+/.test(v) || 'Введите корректный E-mail';
  }

  function matchOfPasswords(password, passwordConfirmation) {
    return password === passwordConfirmation || 'Введённые пароли не совпадают';
  }

  function minLength(minStringLength) {
    return (v) => {
      if (v) {
        return v?.length >= minStringLength || `Минимально ${minStringLength} символов`;
      }
      return true;
    };
  }

  function maxLength(maxStringLength) {
    return (v) => {
      if (v) {
        return v?.length < maxStringLength + 1 || `Максимально ${maxStringLength} символов`;
      }
      return true;
    };
  }

  function min(minNumber) {
    return (v) => v >= minNumber || `Минимальное значение ${minNumber}`;
  }

  function max(maxNumber) {
    return (v) => v <= maxNumber || `Максимальное значение ${maxNumber}`;
  }

  function requiredCustomText(text) {
    return (v) => !!v || text;
  }

  return {
    required,
    requiredImage,
    requiredArray,
    requiredObject,
    email,
    matchOfPasswords,
    minLength,
    maxLength,
    min,
    max,
    requiredCustomText,
  };
}
