import {
  MultiPolygon as MultiPolygonTurf,
  Polygon as PolygonTurf,
} from '@turf/helpers/dist/js/lib/geojson';
import { defineStore } from 'pinia';
import area from '@turf/area';
// eslint-disable-next-line import/no-cycle
import fieldApiService from '@/api/fieldApiService';
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { finalize } from 'rxjs/operators';
import routeNames from '@/router/routeNames';
import { AxiosError } from 'axios';
// eslint-disable-next-line import/no-cycle
import { useMapStore } from '@/stores';
import FieldFeature from '@/modules/map/features/FieldFeature';
import Map from '@/modules/map/data/Map';
import { Field } from '@/modules/field/types/Field';
// eslint-disable-next-line import/no-cycle
import useFieldMap from '@/modules/field/composables/useFieldMap';

export type State = {
  title: string,
  category: string,
  area: number,
  regionId: number,
  districtId: number,
  geometry: PolygonTurf | MultiPolygonTurf,
  field: Field,
  fieldIntersect: boolean,
  loading: boolean,
  serverError: { [key: string]: string[] }
  map: Map,
}

export const useAddOrUpdateFieldStore = defineStore('add-new-field', {
  state: (): State => ({
    title: '',
    category: 'arable',
    area: null,
    regionId: null,
    districtId: null,
    geometry: null,
    field: null,
    fieldIntersect: false,
    loading: false,
    serverError: null,
    map: null,
  }),
  actions: {
    setRegionId(regionId: number) {
      this.regionId = regionId;
    },
    setDistrictId(districtId: number) {
      this.districtId = districtId;
    },
    setTitleField(value: string) {
      this.title = value;
    },
    setCategory(category: string) {
      this.category = category;
    },
    setGeometry(coordinate: PolygonTurf | MultiPolygonTurf) {
      this.geometry = coordinate;
      this.area = Math.round(area({ type: 'Feature', geometry: coordinate, properties: {} }) * 0.01) / 100;
    },
    setFieldIntersect(intersect: boolean) {
      this.fieldIntersect = intersect;
    },
    setMap(map: Map) {
      this.map = map;
    },
    setField(field: Field) {
      this.field = field;
      Object.assign(this, field);
    },
    getActiveField(id: number) {
      this.loading = true;
      fieldApiService.getByID(id)
        .pipe(finalize(() => {
          this.loading = false;
        }))
        .subscribe((data) => {
          this.setField(data);
        });
    },
    saveField() {
      const newField = {
        title: this.title,
        category: this.category,
        area: this.area,
        regionId: this.regionId,
        districtId: this.districtId,
        geometry: this.geometry,
      };
      this.loading = true;
      fieldApiService.add(newField)
        .pipe(finalize(() => {
          this.loading = false;
        }))
        .subscribe(
          (data) => {
            const mapStore = useMapStore();

            // @ts-ignore
            mapStore.setFields([...mapStore.fields, new FieldFeature(data)]);
            router.push({
              name: routeNames.fieldsId,
              params: { id: data.id.toString() },
            });
          },
          this.errorHandler,
        );
    },
    editField() {
      this.loading = true;
      const modifiedField = {
        title: this.title,
        category: this.category,
        area: this.area,
        regionId: this.regionId,
        districtId: this.districtId,
        geometry: this.geometry,
      };
      fieldApiService.edit(this.field.id, modifiedField)
        .pipe(finalize(() => {
          this.loading = false;
        }))
        .subscribe(
          (data) => {
            const mapStore = useMapStore();
            const newFields = mapStore.fields.filter((item) => item.props.id !== this.field.id);
            newFields.push(new FieldFeature(data));
            mapStore.setFields(newFields as FieldFeature[]);
            router.push({
              name: routeNames.fieldsId,
              params: { id: this.field.id.toString() },
            });
          },
          this.errorHandler,
        );
    },
    deleteField() {
      const { removeField } = useFieldMap();

      this.loading = true;
      fieldApiService.delete(this.field.id)
        .pipe(finalize(() => {
          this.loading = false;
        }))
        .subscribe(() => {
          removeField(this.field.id);
          router.push({ name: routeNames.fields });
        });
    },
    errorHandler(e: AxiosError<{ errors: { [key: string]: string[] } }>) {
      if (e.request.status === 422) {
        this.serverError = e.response.data.errors;
      } else {
        throw e;
      }
    },
    clearAllData() {
      this.$reset();
    },
  },
});
